<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.add_transaction") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="saveTransaction()"
                type="success"
                >{{ $t("message.save") }}</el-button
              >
              <el-button
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submitTransaction()"
                type="success"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="resetForm()">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="grid-content">
      <div class="add_patient">
        <el-form
          ref="formTransaction"
          :model="form"
          :rules="rules"
          class="twoform"
        >
          <el-row :gutter="24">
            <el-col :sm="12">
              <el-form-item :label="columns.price.title" prop="price">
                <el-input
                  v-model="form.price"
                  :placeholder="columns.price.title"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :sm="12">
              <el-form-item :label="columns.date.title" prop="date">
                <el-date-picker
                  type="date"
                  v-model="form.date"
                  :placeholder="columns.date.title"
                  style="width: 100%"
                  :format="'dd.MM.yyyy'"
                  :value-format="'dd.MM.yyyy'"
                ></el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :sm="12">
              <el-form-item :label="columns.comment.title" prop="comment">
                <el-input
                  v-model="form.comment"
                  :placeholder="columns.comment.title"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { i18n } from "@/utils/i18n";

import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      loadingButton: false,
      form: {},
      valuta_types: [
        { id: 1, label: "Наличный" },
        { id: 2, label: "Перечисление" },
        { id: 3, label: "Карта" },
      ],
      currency: [
        { id: 1, label: "So'm" },
        { id: 2, label: "Dollar" },
      ],
    };
  },
  created() {
    this.form = JSON.parse(JSON.stringify(this.model));
  },
  async mounted() {},
  computed: {
    ...mapGetters({
      model: "transaction/model",
      rules: "transaction/rules",
      payment: "payment/order_payments",
      columns: "transaction/columns",
    }),
  },
  methods: {
    ...mapActions({
      save: "payment/paymentStore",
      editModel: "transaction/show",
    }),
    submitTransaction() {
      this.$refs["formTransaction"].validate((valid) => {
        if (valid) {
          this.saveAction(true);
        }
      });
    },
    saveTransaction() {
      this.$refs["formTransaction"].validate((valid) => {
        if (valid) {
          this.saveAction(false);
        }
      });
    },
    saveAction(close) {
      let price =
        typeof this.payment.payments[0] != "undefined"
          ? this.payment.payments[0].paid_price
          : 0;
      let val = this.payment.total_price - price;
      if (val == NaN && val < 0) {
        this.$notify({
           title: this.$t("message.validation"),
          type: "error",
          offset: 130,
          dangerouslyUseHTMLString: true,
          message: i18n.t('message.overpaid')+' ' + val,
        });
        return;
      }
      if (val < this.form.price) {
        let dd = this.form.price - val;
        this.$notify({
           title: this.$t("message.validation"),
          type: "error",
          offset: 130,
          dangerouslyUseHTMLString: true,
          message: "более выплаченная сумма  " + dd,
        });
        return;
      }

      this.form.paymentable_id = this.payment.id;
      this.form.paymentable_type = "App\\Order";
      this.form.total_price = this.payment.total_price;
      this.form.debit = 1;
      this.form.rate = 1;

      this.loadingButton = true;
      this.save(this.form)
        .then((res) => {
          this.loadingButton = false;
          this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
          this.form = {};
          if (close) {
            this.$emit("c-close", { reload: true, drawer: "drawerCreate" });
            this.$refs["formTransaction"].resetFields();
          } else {
            this.$emit("c-close", { reload: true, drawer: null });
          }
        })
        .catch((err) => {
          this.loadingButton = false;
          this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
        });
    },
    resetForm() {
      this.$emit("c-close", { drawer: "drawerCreate" });
    },
  },
};
</script>